import styled from "styled-components";
import {
  COLOR_BACKGROUND_DARK_LIGHT,
  COLOR_BACKGROUND_WHITE,
  COLOR_OUTLINE_DARKER,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_PRIMARY_GOLD,
  COLOR_TEXT_DARK_GREY,
  COLOR_TEXT_WHITE,
} from "../../../layouts/default/theme";
import { cssLocalizedFontFamilySerif } from "../../../components/css";

export const Wrapper = styled.div`
  max-width: 60%;
  margin: 4rem auto 4rem auto;
  @media (max-width: 1024px) {
    max-width: 80%;
    margin: 4% auto;
  }
  @media (max-width: 600px) {
    max-width: 95%;
  } ;
`;

export const Divider = styled.hr`
  margin: 0 auto;
  border-top: 1px;
  width: 80%;
  border-color: ${COLOR_OUTLINE_LIGHT_GREY};
  ${Wrapper}.patch-notes & {
    border-color: ${COLOR_OUTLINE_DARKER};
  }

  @media (min-width: 800px) {
    width: 60%;
  }
`;

export const TagWrapper = styled.div`
  margin: 0 auto;
`;

export const TagsTitle = styled.h1`
  width: fit-content;
  display: inline-block;
  margin: auto 25px auto 0;
  font-size: 16px;
  ${cssLocalizedFontFamilySerif};
  font-weight: 400;
  color: ${COLOR_TEXT_DARK_GREY};
  ${Wrapper}.patch-notes & {
    color: ${COLOR_TEXT_WHITE};
  }
`;

export const Tag = styled.button`
  position: relative;
  ${cssLocalizedFontFamilySerif};
  font-size: 14px;
  width: auto;
  height: 36px;
  margin: 25px;
  background-color: transparent;
  color: ${COLOR_TEXT_DARK_GREY};
  border: none;
  padding: 0;
  ${Wrapper}.patch-notes & {
    color: ${COLOR_TEXT_WHITE};
  }
  @media (max-width: 800px) {
    margin: 10px 25px;
  }
  &:hover {
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: -10px;
      width: 20px;
      height: 20px;
      border-right: 1px solid ${COLOR_PRIMARY_GOLD};
      transform: rotate(45deg);
      background-color: ${COLOR_BACKGROUND_WHITE};
      ${Wrapper}.patch-notes & {
        background-color: ${COLOR_BACKGROUND_DARK_LIGHT};
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      right: -10px;
      width: 20px;
      height: 20px;
      border-left: 1px solid ${COLOR_PRIMARY_GOLD};
      transform: rotate(45deg);
      background-color: ${COLOR_BACKGROUND_WHITE};
      ${Wrapper}.patch-notes & {
        background-color: ${COLOR_BACKGROUND_DARK_LIGHT};
      }
    }
  }

  a {
    color: inherit;
    padding: 10px 30px;
    display: block;
    border: 1px solid ${COLOR_PRIMARY_GOLD};
    &:hover {
      text-decoration: none;
    }
  }
`;
