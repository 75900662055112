import React from "react";
import { withPrefix } from "gatsby";

import { Wrapper, ShareBar, Line, Before, BeforeLabel, After, BottomHex, HorizontalLine } from "./style";
import { LinkProps } from "../../../components/link";
import absoluteUrl from "../../../utils/absolute-url";

export interface SectionArticleShareProps {
  className?: string;
  title: string;
  link: LinkProps;
}

const SectionArticleShare: React.FC<SectionArticleShareProps> = ({ className, title, link }) => {
  return (
    <Wrapper className={className} data-testid="share">
      <Before>
        <Line />
        <BeforeLabel data-testid="share:title">{title}</BeforeLabel>
        <Line />
      </Before>
      <ShareBar title={title} url={absoluteUrl(link.internal ? withPrefix(link.url) : link.url)} testId="share"/>
      <After>
        <Line />
      </After>
      <HorizontalLine />
      <BottomHex />
    </Wrapper>
  );
};

export default SectionArticleShare;
