import { TemplateContextData, ContentstackSection } from "../templates/default/typings";
import { SectionType } from "../../../../lib/templates/article/typings";
import { ContentstackAllInternalArticlesQuery as Query } from "../../../../generated/graphql";
import { ImageAssetUrl } from "../../assets/typings";
import { RichTextFieldType } from "../../../fields/rich-text";
import notEmpty from "../../../utils/not-empty";
import { modularBlockPromo } from "../../landing-pages/transforms/modular-blocks";
import {
  ContentstackCarouselItemImage,
  ContentstackCarouselItemHtml5Video,
  ContentstackCarouselItemYouTubeVideo,
} from "../../../../lib/sections/event/carousel/transforms/contentstack";
import { CarouselItemType } from "../../../../lib/sections/event/carousel/typings";
import isInternalUrl from "../../../utils/is-internal-url";
import contentstackNodeToVideoData from "../../html5-videos/transforms/contentstack-node-to-video-data";
import { EmailFormPageConfig, EmailFormGlobalConfig } from "@riotgames/wwpub-components";
import { ArticleTag } from "../../../../lib/sections/article/tags";

const transformAllNormalArticlesQueryNodeToDefaultTemplateContextData = (
  node: Query["all"]["edges"][0]["node"],
): TemplateContextData => {
  // transform category
  const category = !node.category[0]
    ? undefined
    : {
        name: node.category[0].title,
        slug: node.category[0].machine_name,
        url: node.category[0].url.url,
        internal: true,
      };

  // transform authors
  const authors = !node.author
    ? []
    : node.author.filter(notEmpty).map((author) => {
        return {
          name: author.title,
        };
      });

  let sections: ContentstackSection[] = [];

  // transform sections
  switch (node.article_type) {
    case "Patch Notes":
      if (node.patch_notes_body) {
        sections = node.patch_notes_body
          .map((bodySection) => {
            return articleBodySectionToContentstackSection(bodySection, node);
          })
          .filter(notEmpty);
      }
      break;

    case "Normal Article":
      if (node.article_body) {
        sections = node.article_body
          .map((bodySection) => {
            return articleBodySectionToContentstackSection(bodySection, node);
          })
          .filter(notEmpty);
      }
      break;
  }

  return {
    uid: node.uid,
    title: node.title,
    description: node.description,
    published_date: node.date,
    category,
    imageUrl: node.banner ? (node.banner.url as ImageAssetUrl) : undefined,
    authors,
    sections,
    article_tags: node.article_tags as ArticleTag[],
  };
};

export default transformAllNormalArticlesQueryNodeToDefaultTemplateContextData;

type ArticleBody = NonNullable<Query["all"]["edges"][0]["node"]["article_body"]>;
type PatchNotesBody = NonNullable<Query["all"]["edges"][0]["node"]["patch_notes_body"]>;
type BodySection = ArticleBody[0] & PatchNotesBody[0] & { email_form: EmailFormPageConfig };

export const articleBodySectionToContentstackSection = (
  section: Partial<BodySection>,
  options: {
    title: string;
  },
  globalConfig: EmailFormGlobalConfig,
  locale = "",
): ContentstackSection | null => {
  const keys = Object.keys(section) as Array<keyof BodySection>;

  for (const key of keys) {
    if (!section[key]) continue;

    switch (key) {
      case "accordion":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "audio":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "author_bio":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "cta_list":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "call_out":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "html5_video":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "image_gallery":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "images":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "media_carousel":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "patch_notes":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!, options.title);
      case "promo_module":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "rich_text_editor":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "separator":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "video_youtube":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "tweet_embed":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "tabs":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!);
      case "email_form":
        return TRANSFORM_BODY_SECTION_MAP[key](section[key]!, globalConfig, locale);
    }
  }

  return null;
};

const TRANSFORM_BODY_SECTION_MAP = {
  accordion: (section: BodySection["accordion"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_ACCORDION,
      props: {
        sections: section.accordion_configuration.map(({ accordion_title, accordion_description }) => {
          return {
            title: accordion_title,
            content: accordion_description as RichTextFieldType,
          };
        }),
      },
    };
  },
  audio: (section: BodySection["audio"]): ContentstackSection | null => {
    if (!section) return null;
    if (!section.audio_file) return null;
    return {
      type: SectionType.ARTICLE_AUDIO,
      props: {
        src: section.audio_file.url,
        title: section.caption || undefined,
      },
    };
  },
  author_bio: (section: BodySection["author_bio"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_AUTHOR_LIST,
      props: {
        authorList: section.author_selection.filter(notEmpty).map((author) => {
          return {
            avatar: author.avatar ? (author.avatar.url as ImageAssetUrl) : undefined,
            name: author.title,
            fullName: author.full_name || undefined,
            title: author.job_title || undefined,
            bio: author.author_bio || undefined,
          };
        }),
      },
    };
  },
  call_out: (section: BodySection["call_out"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_CALL_OUT,
      props: {
        body: section.body as RichTextFieldType,
        image: section.image ? (section.image.url as ImageAssetUrl) : undefined,
        external_link: section.external_link || undefined,
        open_in_new_window: section.open_in_new_window || undefined,
      },
    };
  },
  cta_list: (section: BodySection["cta_list"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_BUTTON_LIST,
      props: {
        buttons: section.items.map(({ link, style }) => {
          return {
            variant: style === "Primary" ? "primary" : "secondary",
            link: {
              text: link.title ? link.title : link.href,
              url: link.href,
              internal: isInternalUrl(link.href),
            },
          };
        }),
      },
    };
  },
  html5_video: (section: BodySection["html5_video"]): ContentstackSection | null => {
    if (!section) return null;

    const videos = section.html5_video_selection
      .filter(notEmpty)
      .map((videoEntry) => contentstackNodeToVideoData(videoEntry))
      .filter(notEmpty);

    if (videos.length <= 0) return null;

    return {
      type: SectionType.ARTICLE_HTML5VIDEO,
      props: {
        videos,
        autoplay: !!section.autoplay,
        controls: !!section.controls,
      },
    };
  },
  image_gallery: (section: BodySection["image_gallery"]): ContentstackSection | null => {
    if (!section) return null;

    const images = section.image_selection
      .map((image) => {
        if (!image.select_image) return null;
        return {
          title: image.image_title || undefined,
          url: image.select_image.url as ImageAssetUrl,
        };
      })
      .filter(notEmpty);

    if (images.length <= 0) return null;

    return {
      type: SectionType.ARTICLE_GALLERY,
      props: {
        title: section.title || undefined,
        images,
      },
    };
  },
  images: (section: BodySection["images"]): ContentstackSection | null => {
    if (!section) return null;

    const images = section.images
      .map(({ image, title, caption, value, external_link, open_in_new_window }) => {
        if (!image) return null;
        return {
          title: title || undefined,
          caption: caption || undefined,
          value: value || undefined,
          imageUrl: image.url as ImageAssetUrl,
          external_link: external_link || undefined,
          open_in_new_window: open_in_new_window || undefined,
        };
      })
      .filter(notEmpty);

    if (images.length <= 0) return null;

    return {
      type: SectionType.ARTICLE_IMAGE_GRID,
      props: {
        images,
      },
    };
  },
  media_carousel: (section: BodySection["media_carousel"]): ContentstackSection | null => {
    if (!section) return null;

    const items = section.items
      .map(({ title, description, thumbnail, image, html5_video, youtube_video_id }) => {
        if (!thumbnail) return null;

        const commonProps = {
          title,
          description: description ? (description as RichTextFieldType) : undefined,
          thumbnailUrl: thumbnail.url as ImageAssetUrl,
        };

        if (image && image.url) {
          const item: ContentstackCarouselItemImage = {
            ...commonProps,
            type: CarouselItemType.IMAGE,
            imageUrl: image.url as ImageAssetUrl,
          };
          return item;
        } else if (html5_video && html5_video[0] && html5_video[0].video.file) {
          const item: ContentstackCarouselItemHtml5Video = {
            ...commonProps,
            type: CarouselItemType.HTML5_VIDEO,
            video: {
              posterUrl: html5_video[0].static_image ? (html5_video[0].static_image.url as ImageAssetUrl) : undefined,
              sources: [
                {
                  url: html5_video[0].video.file.url,
                  type: html5_video[0].video.file.content_type,
                },
              ],
            },
          };
          return item;
        } else if (youtube_video_id) {
          const item: ContentstackCarouselItemYouTubeVideo = {
            ...commonProps,
            type: CarouselItemType.YOUTUBE_VIDEO,
            youtubeVideoId: youtube_video_id,
          };
          return item;
        } else {
          return null;
        }
      })
      .filter(notEmpty);

    if (items.length <= 0) return null;

    return {
      type: SectionType.EVENT_CAROUSEL,
      props: {
        introduction: section.introduction ? (section.introduction as RichTextFieldType) : undefined,
        items,
      },
    };
  },
  patch_notes: (section: BodySection["patch_notes"], title: string): ContentstackSection | null => {
    if (!section) return null;

    return {
      type: SectionType.PATCH_NOTES_BODY,
      props: {
        title,
        content: section.html,
      },
    };
  },
  promo_module: (section: BodySection["promo_module"]): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockPromo(section);

    if (!props) return null;

    return {
      type: SectionType.ARTICLE_PROMO,
      props,
    };
  },
  rich_text_editor: (section: BodySection["rich_text_editor"]): ContentstackSection | null => {
    if (!section) return null;
    if (!section.side_image.image) {
      return {
        type: SectionType.ARTICLE_HTML,
        props: {
          content: section.rich_text_editor as RichTextFieldType,
        },
      };
    } else {
      return {
        type: SectionType.ARTICLE_HTML_MEDIA_SPLIT,
        props: {
          html: section.rich_text_editor as RichTextFieldType,
          mediaUrl: section.side_image.image.url as ImageAssetUrl,
          order: section.side_image.order === "Image First" ? "media-first" : "html-first",
        },
      };
    }
  },
  separator: (section: BodySection["separator"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_SEPARATOR,
      props: {
        hexLine: section.type === "Ornamental",
        custom: section.type === "Custom",
        image: section.custom_divider || undefined,
      },
    };
  },
  video_youtube: (section: BodySection["video_youtube"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_YOUTUBE,
      props: {
        videoId: section.video_id,
      },
    };
  },
  tweet_embed: (section: BodySection["tweet_embed"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_TWEET_LIST,
      props: {
        tweets: section.tweet.map(({ tweet_id }) => {
          return tweet_id;
        }),
      },
    };
  },
  tabs: (section: BodySection["tabs"]): ContentstackSection | null => {
    if (!section) return null;
    return {
      type: SectionType.ARTICLE_TAB_LIST,
      props: {
        tabs: section.tab_configuration.map((tab) => {
          return {
            title: tab.tab_name,
            body: tab.tab_content as RichTextFieldType,
          };
        }),
      },
    };
  },
  email_form: (
    pageConfig: EmailFormPageConfig,
    globalConfig: EmailFormGlobalConfig,
    lang: string,
  ): ContentstackSection | null => {
    if (!pageConfig) return null;
    return {
      type: SectionType.EMAIL_FORM,
      props: {
        pageConfig,
        globalConfig,
        lang,
      },
    };
  },
};
