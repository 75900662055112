import React from "react";
import { Link as GatsbyLink } from "gatsby-plugin-intl";
import { Wrapper, TagsTitle, Divider, TagWrapper, Tag } from "./style";

export interface ArticleTag {
  title: string;
  machine_name: string;
  is_hidden: boolean;
  url: {
    url: string;
  };
}

export interface SectionArticleTagsProps {
  tags?: ArticleTag[];
  patchNotes?: boolean;
  tagsTitle?: string;
}

const SectionArticleTags: React.FC<SectionArticleTagsProps> = ({ tags, patchNotes, tagsTitle }) => {
  if (!tags) {
    return null;
  }

  return (
    <Wrapper className={patchNotes ? "patch-notes" : ""}>
      <Divider />
      <TagWrapper>
        <TagsTitle>{tagsTitle}:</TagsTitle>
        {tags.map((tag, index) => {
          const urlWithTrailingSlash = tag.url.url.replace(/\/?$/, "/");
          return tag.is_hidden ? null : (
            <Tag key={index}>
              <GatsbyLink to={urlWithTrailingSlash}>{tag.title}</GatsbyLink>
            </Tag>
          );
        })}
      </TagWrapper>
      <Divider />
    </Wrapper>
  );
};

export default SectionArticleTags;
