import styled from "styled-components";
import { ReactComponent as DiamondsSvg } from "../../../assets/symbols/diamonds.svg";

import ShareBarComponent from "../../../components/share-bar";
import {
  COLUMN_WIDTH,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_TEXT_DARK_GREY,
  COLOR_OUTLINE_MEDIUM_GREY,
  MEDIA_MOBILE_ALL,
} from "../../../layouts/default/theme";
import { rem } from "../../../utils/style";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../components/css";

export const ShareBar = styled(ShareBarComponent)`
  display: block;
  margin-top: 3px;
  text-align: center;
`;

export const Wrapper = styled.div`
  max-width: 400px;
  margin: 4% auto;

  @media (max-width: 440px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Line = styled.hr`
  flex: 1 1 0%;
  border: 0;
  width: 100%;
  height: 1px;

  background: ${COLOR_OUTLINE_LIGHT_GREY};
`;

export const Before = styled.div`
  display: flex;
  align-items: center;
`;

export const BeforeLabel = styled.label`
  flex-shrink: 0;
  margin: 0 10px;

  color: ${COLOR_TEXT_DARK_GREY};
  font-size: ${rem(10)};
  letter-spacing: 0.15em;
  ${cssLocalizedUppercase}

  @media ${MEDIA_MOBILE_ALL} {
    font-size: 10px;
    ${cssLocalizedLineHeight(14 / 10)}
    letter-spacing: 0.1em;
  }
`;

export const After = styled.div``;

export const HorizontalLine = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1.5px;
  height: 20px;
  margin-top: -9px;
  background: ${COLOR_OUTLINE_LIGHT_GREY};
`;

export const BottomHex = styled(DiamondsSvg)`
  display: block;
  height: 20px;
  width: 12px;
  margin: 20px auto 0 auto;

  .st0 {
    fill: none;
    stroke: ${COLOR_OUTLINE_MEDIUM_GREY};
  }
`;
