import styled, { css } from "styled-components";
import { rem } from "../../../utils/style";

import {
  MOBILE_MARGIN,
  MAX_WIDTH,
  COLUMN_WIDTH,
  MEDIA_MOBILE_ALL,
  MEDIA_TABLET_AND_SMALLER,
  COLOR_PRIMARY_DARK_GOLD,
} from "../../../layouts/default/theme";

import { patchNotesDesktopStyle, patchNotesMobileStyle } from "./patch-notes-style";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../components/css";
import { ReactComponent as UpSvg } from "../../../assets/arrows/up.svg";

const WRAPPER_MAX_WIDTH = MAX_WIDTH - COLUMN_WIDTH * 4;
const NAV_WIDTH = COLUMN_WIDTH * 4;

const ANCHOR_SIZE = 6;

export const Wrapper = styled.section`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  max-width: ${WRAPPER_MAX_WIDTH}px;
  margin: 0 auto;
  padding: 0 ${COLUMN_WIDTH}px;
`;

export const Content = styled.div`
  flex: 1;
  max-width: 100%;
  font-size: ${rem(14)};
  letter-spacing: 0.5px;
  ${cssLocalizedLineHeight(1.6)}
`;
export const Title = styled.h4`
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 10px;
  ${cssLocalizedUppercase}
  margin: 0;
  margin-left: -5px;
  ${cssLocalizedLineHeight(2)}
`;

export const NavWrapper = styled.aside`
  box-sizing: border-box;
  flex: 0 0 ${NAV_WIDTH}px;
  padding-left: ${COLUMN_WIDTH}px;
`;

export const Nav = styled.nav<{ stickyOffset?: number }>`
  position: sticky;
  width: 100%;
  margin-top: 60px;
  top: ${({ stickyOffset }) => (stickyOffset || 0) + 60 + "px"};
`;

export const Anchor = styled.div`
  position: absolute;
  left: 0px;
  top: 11px;

  width: ${ANCHOR_SIZE}px;
  height: ${ANCHOR_SIZE}px;

  opacity: 0;
  transform: translate(0px, 0);

  transition: opacity 0.2s, transform 0.2s;

  ::before {
    content: "";
    display: block;
    border: ${ANCHOR_SIZE / 6}px solid white;
    width: ${ANCHOR_SIZE / 2}px;
    height: ${ANCHOR_SIZE / 2}px;
    transform: rotate(45deg);
  }
`;

const AnchorSelected = `
  ${Anchor} {
    opacity: 1;
    transform: translate(-12px, 0);
  }
`;

export const NavItem = styled.a<{ selected: boolean }>`
  display: block;
  position: relative;
  ${cssLocalizedUppercase}
  font-size: ${rem(12)};
  color: ${COLOR_PRIMARY_DARK_GOLD};
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  transition: opacity 0.2s;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? `
    opacity: 1;
    ${AnchorSelected}
    `
      : `opacity: 0.5;`}

  :hover {
    opacity: 1;
    text-decoration: none;

    ${AnchorSelected}
  }
`;

export const NavText = styled.span`
  display: block;
  padding: 7px 0;
`;

export const BackToTopArrow = styled(UpSvg)`
  fill: white;
  width: 16px;
  transition: transform 300ms;
`;

export const BackToTop = styled.button<{ active: boolean }>`
  position: fixed;
  display: block;
  right: 20px;
  bottom: 20px;
  height: 50px;
  width: 50px;
  min-width: 50px;
  min-height: 50px;

  opacity: 0;
  visibility: hidden;

  z-index: 100;
  background-color: #000913;
  border: 1px solid white;

  transition: opacity 300ms, visibility 0ms linear 300ms;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 0.5;
      transition: opacity 300ms, visibility 0ms;
    `}

  &.hover {
    opacity: 1;
    ${BackToTopArrow} {
      transform: translateY(-2px);
    }
  }
`;

// RESPONSIVE
export const ResponsiveWrapper = styled(Wrapper)`
  ${Content} {
    ${patchNotesDesktopStyle}
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    padding: 0 5%;

    ${Content} {
      box-sizing: border-box;
      padding: ${MOBILE_MARGIN}px 0;
    }

    ${NavWrapper} {
      display: none;

      position: fixed;
      top: ${MOBILE_MARGIN}px;
      right: ${MOBILE_MARGIN}px;
    }
  }

  @media ${MEDIA_MOBILE_ALL} {
    ${Content} {
      ${patchNotesMobileStyle}
    }
  }
`;
