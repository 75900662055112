import React from "react";
import { ThemeProvider } from "styled-components";

import { COLOR_OUTLINE_DARKER, COLOR_PRIMARY_GOLD, COLOR_BACKGROUND_DARK_LIGHT } from "../../../layouts/default/theme";

import { BREAKPOINT, ResponsiveWrapper, Media, DetailsWrapper, Details, TitleWrapper, Title, Meta } from "./style";
import { LinkProps } from "../../../components/link";

export interface SectionPatchNotesHeaderProps {
  media?: React.ReactElement;
  title: string;
  date: Date;
  authors?: Array<{
    name: string;
    // may support url in the future
  }>;
  link: LinkProps;
  className?: string;
}

const SectionPatchNotesHeader: React.FC<SectionPatchNotesHeaderProps> = ({
  media,
  title,
  date,
  authors,
  link,
  className,
}) => {
  return (
    <ResponsiveWrapper className={className}>
      <Media>{media}</Media>
      <DetailsWrapper>
        <Details>
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>

          <ThemeProvider
            theme={{
              borderColor: COLOR_OUTLINE_DARKER,
              authorColor: COLOR_PRIMARY_GOLD,
              breakpoint: BREAKPOINT,
            }}
          >
            <Meta
              title={title}
              date={date}
              authors={authors}
              link={link}
              backgroundColor={COLOR_BACKGROUND_DARK_LIGHT}
            />
          </ThemeProvider>
        </Details>
      </DetailsWrapper>
    </ResponsiveWrapper>
  );
};

export default SectionPatchNotesHeader;
