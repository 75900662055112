import styled from "styled-components";
import { rem } from "../../../utils/style";

import {
  MAX_WIDTH,
  COLUMN_WIDTH,
  COLOR_OUTLINE_DARKER,
  COLOR_TEXT_DARK_GREY,
  MEDIA_MOBILE_ALL,
  MEDIA_TABLET_AND_SMALLER,
  MOBILE_MARGIN,
} from "../../../layouts/default/theme";

import MetaComponent from "../../article/header/meta";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../../components/css";

const WRAPPER_MAX_WIDTH = MAX_WIDTH - COLUMN_WIDTH * 4;
export const Wrapper = styled.section`
  position: relative;
  width: 100%;
  max-width: ${WRAPPER_MAX_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Media = styled.div`
  position: relative;
  width: 100%;
  height: 60vh;

  html.is-ms-ie & {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  img {
    width: 100%;
    height: 100%;

    html.is-ms-ie & {
      height: auto;
    }
  }

  ::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, rgba(0, 9, 19, 1) 1%, rgba(0, 9, 19, 0) 100%);
  }
`;
export const DetailsWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 ${COLUMN_WIDTH}px;
  z-index: 1;
`;
export const Details = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid ${COLOR_OUTLINE_DARKER};
  border-top: 0;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  ::before,
  ::after {
    content: "";
    flex: 1 1 100%;
    height: 1px;
    background-color: ${COLOR_OUTLINE_DARKER};

    html.is-ms-ie & {
      display: block;
    }
  }
`;
export const Title = styled.h1`
  max-width: 90%;
  text-align: center;
  flex: 1 0 auto;
  margin: -0.5em 15px 0;
  padding-bottom: 0.25em;

  font-weight: 800;
  font-size: ${rem(60)};
  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedUppercase}
  ${cssLocalizedItalic}
  letter-spacing: 3px;
  ${cssLocalizedLineHeight(0.9)}
`;

export const Meta = styled(MetaComponent)`
  border-top: 1px solid ${COLOR_OUTLINE_DARKER};
  color: ${COLOR_TEXT_DARK_GREY};
`;

export const BREAKPOINT = 800;
export const ResponsiveWrapper = styled(Wrapper)`
  @media ${MEDIA_TABLET_AND_SMALLER} {
    ${DetailsWrapper} {
      padding: 0;
    }
  }

  @media (max-width: ${BREAKPOINT + "px"}) {
    ${Media} {
      ::after {
        display: none;
      }
    }
    ${Details} {
      border: none;
    }

    ${TitleWrapper} {
      margin-top: ${MOBILE_MARGIN * 1.5}px;
      ::before,
      ::after {
        display: none;
      }
    }
    ${Title} {
      font-size: ${rem(24)};
      text-align: left;
      ${cssLocalizedLineHeight(1)}
      transform: none;
      margin: 0 ${MOBILE_MARGIN}px;
    }
    ${Meta} {
      margin-top: 10px;
      border-top: 0;
    }
  }
`;
