/**
 * Returns absolute url
 * @param _url Relative url
 * @param _base Base url to which to compare the target url to
 */
const absoluteUrl = (_url: string, _base?: string): string => {
  if (typeof window === "undefined" || !window.URL) return _url;
  _base = _base || window.location.href;

  const url = new URL(_url, _base);
  return url.href;
};

export default absoluteUrl;
