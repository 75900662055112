export interface HoverOptions {
  target: HTMLElement;
  hoverCallback?: (el: HTMLElement, active: boolean) => void;
}

/**
 * In order to avoid sticky hover on touch screens when applying styles on :hover CSS rules,
 * we use javascript to detect whether the interaction was using "touch" or "mouse"
 * and apply class "hover" on element
 */
export default class Hover {
  private _target: HTMLElement;
  private _hoverCallback: (el: HTMLElement, active: boolean) => void;
  private _eventHandler: (event: TouchEvent | MouseEvent) => void;
  private _active = false;

  constructor({
    target,
    hoverCallback = (el, active) => {
      if (active) el.classList.add("hover");
      else el.classList.remove("hover");
    },
  }: HoverOptions) {
    this._target = target;
    this._hoverCallback = hoverCallback;

    let enabled = false;

    this._eventHandler = (e: TouchEvent | MouseEvent) => {
      switch (e.type) {
        case "touchstart":
          enabled = false;
          break;

        case "mouseenter":
          hoverCallback(e.currentTarget as HTMLElement, enabled);
          this._active = enabled;
          enabled = true;
          break;

        case "mouseleave":
          hoverCallback(e.currentTarget as HTMLElement, false);
          this._active = false;
          break;
      }
    };
    target.addEventListener("touchstart", this._eventHandler);
    target.addEventListener("mouseenter", this._eventHandler);
    target.addEventListener("mouseleave", this._eventHandler);
  }

  destroy() {
    if (this._active) {
      this._hoverCallback(this._target, false);
      this._active = false;
    }

    this._target.addEventListener("touchstart", this._eventHandler);
    this._target.addEventListener("mouseenter", this._eventHandler);
    this._target.addEventListener("mouseleave", this._eventHandler);
  }
}
