import React from "react";
import { FacebookSvg, LinkSvg, RedditSvg, TwitterSvg, VkSvg, LineSvg } from "../../../assets/social";
import BCPLocale from "../../../enums/bcp-locale";

export enum ShareType {
  POPUP = "popup",
  NEW_WINDOW = "new-window",
  CLIPBOARD = "clipboard",
}

export interface BaseShareItem {
  readonly icon: React.FunctionComponent<React.SVGProps<HTMLOrSVGElement>>;
  readonly text: string;
  readonly type: ShareType;
  readonly className: string;
  readonly excludedLocales: Array<BCPLocale>;
}

export interface ShareClipboardItem extends BaseShareItem {
  readonly type: ShareType.CLIPBOARD;
}

export interface ShareUrlTemplateItem extends BaseShareItem {
  readonly urlTemplate: string;
  readonly type: ShareType.NEW_WINDOW | ShareType.POPUP;
}

export type ShareItem = ShareClipboardItem | ShareUrlTemplateItem;

const items: readonly ShareItem[] = [
  {
    icon: VkSvg,
    className: "icon-vk",
    text: "share-on-vk.action",
    urlTemplate: "https://vk.com/share.php?url=%url%",
    type: ShareType.POPUP,
    excludedLocales: [],
  },
  {
    icon: FacebookSvg,
    className: "icon-fb",
    text: "share-on-facebook.action",
    urlTemplate: "https://www.facebook.com/sharer.php?u=%url%",
    type: ShareType.POPUP,
    excludedLocales: [BCPLocale["ru-ru"]],
  },
  {
    icon: TwitterSvg,
    className: "icon-tw",
    text: "share-on-twitter.action",
    urlTemplate: "https://twitter.com/intent/tweet?url=%url%",
    type: ShareType.POPUP,
    excludedLocales: [],
  },
  {
    icon: RedditSvg,
    className: "icon-rd",
    text: "share-on-reddit.action",
    urlTemplate: "https://www.reddit.com/submit?url=%url%&title=%title%",
    type: ShareType.NEW_WINDOW,
    excludedLocales: [],
  },
  {
    icon: LineSvg,
    className: "icon-line",
    text: "share-on-line.action",
    urlTemplate: "https://social-plugins.line.me/lineit/share?url=%url%",
    type: ShareType.POPUP,
    excludedLocales: [],
  },
  {
    icon: LinkSvg,
    className: "icon-lk",
    text: "copy-link.action",
    type: ShareType.CLIPBOARD,
    excludedLocales: [],
  },
];

export default items;
