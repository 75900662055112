import styled from "styled-components";
import { cssLocalizedUppercase } from "../css";
import riotLocaleToBcp47 from "../../utils/riot-locale-to-bcp47";
import RiotLocale from "../../enums/riot-locale";

export const Wrapper = styled.div`
  height: 32px;
  display: inline-block;
  color: #cca75c;
`;

export const List = styled.ul`
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
`;

export const Item = styled.li`
  height: 100%;
  flex: 1 1 auto;
  position: relative;
  text-align: center;

  &.hidden {
    display: none;
  }

  &.icon-vk {
    svg {
      margin-top: 5%;
      height: 90%;
    }

    html:not(:lang(${riotLocaleToBcp47(RiotLocale.ru_RU)})) & {
      display: none; /* hide VK on non ru_RU locales */
    }
  }

  &.icon-line {
    svg {
      margin-top: -15%;
      height: 130%;
    }

    html:not(:lang(${riotLocaleToBcp47(RiotLocale.ja_JP)})) & {
      display: none; /* hide VK on non ja_JP locales */
    }
  }

  &.icon-rd {
    html:lang(${riotLocaleToBcp47(RiotLocale.ja_JP)}) & {
      display: none; /* hide Reddit on ja_JP locale */
    }
  }
`;

const BUTTON_PADDING = 10;
export const Button = styled.button`
  height: 100%;
  width: 40px;
  padding: 8px ${BUTTON_PADDING}px;
  border: 0;
  background-color: transparent;
  color: inherit;

  svg {
    display: block;
    height: 100%;
    fill: currentColor;
    margin: 0 auto;
  }
`;

export const CopiedMessage = styled.span<{ backgroundColor?: string }>`
  width: 70px;
  box-sizing: border-box;
  padding: 5px;

  bottom: 0;
  left: 50%;

  ${cssLocalizedUppercase}
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.1em;

  position: absolute;
  transform: translate(-50%, calc(100% - ${BUTTON_PADDING / 2}px));
  background-color: ${({ backgroundColor }) => backgroundColor || "white"};

  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 0ms 300ms;
  will-change: opacity;

  &.active {
    opacity: 1;
    visibility: visible;
    transition: opacity 300ms, visibility 0ms 0ms;
  }

  @media (max-width: 500px) {
    left: 30%;
  }
`;
