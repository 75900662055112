enum BCPLocale {
  "cs-cz" = "cs-cz",
  "de-de" = "de-de",
  "el-gr" = "el-gr",
  "en-au" = "en-au",
  "en-gb" = "en-gb",
  "en-ph" = "en-ph",
  "en-pl" = "en-pl",
  "en-sg" = "en-sg",
  "en-us" = "en-us",
  "es-ar" = "es-ar",
  "es-es" = "es-es",
  "es-mx" = "es-mx",
  "fr-fr" = "fr-fr",
  "hu-hu" = "hu-hu",
  "id-id" = "id-id",
  "it-it" = "it-it",
  "ja-jp" = "ja-jp",
  "ko-kr" = "ko-kr",
  "ms-my" = "ms-my",
  "pl-pl" = "pl-pl",
  "pt-br" = "pt-br",
  "ro-ro" = "ro-ro",
  "ru-ru" = "ru-ru",
  "th-th" = "th-th",
  "tr-tr" = "tr-tr",
  "vi-vn" = "vi-vn",
  "zh-cn" = "zh-cn",
  "zh-tw" = "zh-tw",
}

export default BCPLocale;

export function isBCPLocale(s: string): s is BCPLocale {
  return Object.keys(BCPLocale).includes(s);
}

export function toBCPLocale(s: string, fallback: BCPLocale): BCPLocale {
  return s && isBCPLocale(s) ? s : fallback;
}
