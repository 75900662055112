import React from "react";
import { withPrefix } from "gatsby";
import { ResponsiveWrapper, Date, Info, Category, Author, AuthorLabel, AuthorList, ShareBar } from "./style";

import { LinkProps } from "../../../../components/link";
import absoluteUrl from "../../../../utils/absolute-url";
import { useIntl } from "gatsby-plugin-intl";

export interface MetaProps {
  title: string;
  date: Date;
  category?: LinkProps & {
    name: string;
  };
  authors?: Array<{
    name: string;
    // may support url in the future
  }>;
  link: LinkProps;
  backgroundColor?: string;
  className?: string;
  testId?: string;
}

const SectionArticleHeaderMeta: React.FC<MetaProps> = ({
  title,
  date,
  category,
  authors,
  link,
  backgroundColor,
  className,
  testId,
}) => {
  const intl = useIntl();
  return (
    <ResponsiveWrapper className={className}>
      <Date dateTime={date} testId={`${testId}:date`}/>
      <Info className={category ? "contains-category" : ""}>
        {category && <Category {...category} testId={`${testId}:category`}>{category.name}</Category>}
        {authors && authors.length > 0 && (
          <Author>
            <AuthorLabel data-testid={`${testId}:authors-string`}>
              {authors.length > 1
                ? intl.formatMessage({ id: "author.title.plural" })
                : intl.formatMessage({ id: "author.title.singular" })}
            </AuthorLabel>
            <AuthorList data-testid={`${testId}:authors-list`}>{authors.map((author) => author.name).join(", ")}</AuthorList>
          </Author>
        )}
      </Info>
      <ShareBar
        title={title}
        url={absoluteUrl(link.internal ? withPrefix(link.url) : link.url)}
        backgroundColor={backgroundColor}
        testId={`${testId}:sharebar`}
      />
    </ResponsiveWrapper>
  );
};

export default SectionArticleHeaderMeta;
