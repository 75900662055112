import { VideoSource } from "../../../components/video";

export enum CarouselItemType {
  IMAGE = "image",
  HTML5_VIDEO = "html5_video",
  YOUTUBE_VIDEO = "youtube_video",
}

export interface CarouselItemBase {
  type: CarouselItemType;
  title: string;
  thumbnail: React.ReactElement;
  description?: React.ReactElement;
}

export interface CarouselItemImage extends CarouselItemBase {
  type: CarouselItemType.IMAGE;
  image: React.ReactElement;
}

export interface CarouselItemHtml5Video extends CarouselItemBase {
  type: CarouselItemType.HTML5_VIDEO;
  videoPoster?: React.ReactElement;
  videoSources: VideoSource[];
}

export interface CarouselItemYouTubeVideo extends CarouselItemBase {
  type: CarouselItemType.YOUTUBE_VIDEO;
  youtubeVideoId: string;
}

export type CarouselItem = CarouselItemImage | CarouselItemHtml5Video | CarouselItemYouTubeVideo;
