import { SectionArticleAccordionProps } from "../../sections/article/accordion";
import { SectionArticleAudioProps } from "../../sections/article/audio";
import { SectionArticleAuthorListProps } from "../../sections/article/author-list/typings";
import { SectionArticleButtonListProps } from "../../sections/article/button-list";
import { SectionArticleCallOutProps } from "../../sections/article/call-out";
import { SectionArticleGalleryProps } from "../../sections/article/gallery";
import { SectionArticleHeaderProps } from "../../sections/article/header";
import { SectionArticleHtmlProps } from "../../sections/article/html";
import { SectionArticleHtmlMediaSplitProps } from "../../sections/article/html-media-split";
import { SectionArticleRelatedArticlesProps } from "../../sections/article/related-articles";
import { SectionArticleSeparatorProps } from "../../sections/article/separator";
import { SectionArticleShareProps } from "../../sections/article/share";
import { SectionArticleTabListProps } from "../../sections/article/tab-list";
import { SectionArticleTweetListProps } from "../../sections/article/tweet-list";
import { SectionArticleImageGridProps } from "../../sections/article/image-grid";
import { SectionArticleFullWidthImageProps } from "../../sections/article/full-width-image";
import { Props as SectionPromoProps } from "../../sections/promo";
import { SectionArticleYouTubeProps } from "../../sections/article/youtube";
import { SectionArticleHTML5VideoProps } from "../../sections/article/html5-video";
import { SectionEventHeaderProps } from "../../sections/event/header";
import { SectionEventCarouselProps } from "../../sections/event/carousel";
import { SectionEventFooterProps } from "../../sections/event/footer";
import { SectionPatchNotesBodyProps } from "../../sections/patch-notes/body";
import { SectionPatchNotesHeaderProps } from "../../sections/patch-notes/header";
import { EmailFormContentstackProps } from '@riotgames/wwpub-components'
import { SectionArticleTagsProps } from "../../sections/article/tags";

export enum SectionType {
  ARTICLE_ACCORDION = "article_accordion",
  ARTICLE_AUDIO = "article_audio",
  ARTICLE_AUTHOR_LIST = "article_author_list",
  ARTICLE_BUTTON_LIST = "article_button_list",
  ARTICLE_CALL_OUT = "article_call_out",
  ARTICLE_GALLERY = "article_gallery",
  ARTICLE_HEADER = "article_header",
  ARTICLE_HTML = "article_html",
  ARTICLE_HTML_MEDIA_SPLIT = "article_html_media_split",
  ARTICLE_RELATED_ARTICLES = "article_related_articles",
  ARTICLE_SEPARATOR = "article_separator",
  ARTICLE_SHARE = "article_share",
  ARTICLE_TAB_LIST = "article_tab_list",
  ARTICLE_TWEET_LIST = "article_tweet_list",
  ARTICLE_IMAGE_GRID = "article_image_grid",
  ARTICLE_FULL_WIDTH_IMAGE = "article_full_width_image",
  ARTICLE_PROMO = "article_promo",
  ARTICLE_YOUTUBE = "article_youtube",
  ARTICLE_HTML5VIDEO = "article_html5video",
  EVENT_HEADER = "event_header",
  EVENT_CAROUSEL = "event_carousel",
  EVENT_FOOTER = "event_footer",
  PATCH_NOTES_BODY = "patch_notes_body",
  PATCH_NOTES_HEADER = "patch_notes_header",
  EMAIL_FORM = "email_form",
  ARTICLE_TAGS = "article_tags",
}

export type Section =
  | {
      key: string;
      type: SectionType.ARTICLE_ACCORDION;
      props: SectionArticleAccordionProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_AUDIO;
      props: SectionArticleAudioProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_AUTHOR_LIST;
      props: SectionArticleAuthorListProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_BUTTON_LIST;
      props: SectionArticleButtonListProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_CALL_OUT;
      props: SectionArticleCallOutProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_GALLERY;
      props: SectionArticleGalleryProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_HEADER;
      props: SectionArticleHeaderProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_HTML;
      props: SectionArticleHtmlProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_HTML_MEDIA_SPLIT;
      props: SectionArticleHtmlMediaSplitProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_RELATED_ARTICLES;
      props: SectionArticleRelatedArticlesProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_SEPARATOR;
      props: SectionArticleSeparatorProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_SHARE;
      props: SectionArticleShareProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_TAB_LIST;
      props: SectionArticleTabListProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_TWEET_LIST;
      props: SectionArticleTweetListProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_IMAGE_GRID;
      props: SectionArticleImageGridProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_FULL_WIDTH_IMAGE;
      props: SectionArticleFullWidthImageProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_PROMO;
      props: SectionPromoProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_YOUTUBE;
      props: SectionArticleYouTubeProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_HTML5VIDEO;
      props: SectionArticleHTML5VideoProps;
    }
  | {
      key: string;
      type: SectionType.EVENT_HEADER;
      props: SectionEventHeaderProps;
    }
  | {
      key: string;
      type: SectionType.EVENT_CAROUSEL;
      props: SectionEventCarouselProps;
    }
  | {
      key: string;
      type: SectionType.EVENT_FOOTER;
      props: SectionEventFooterProps;
    }
  | {
      key: string;
      type: SectionType.PATCH_NOTES_BODY;
      props: SectionPatchNotesBodyProps;
    }
  | {
      key: string;
      type: SectionType.PATCH_NOTES_HEADER;
      props: SectionPatchNotesHeaderProps;
    }
  | {
      key: string;
      type: SectionType.EMAIL_FORM;
      props: EmailFormContentstackProps;
    }
  | {
      key: string;
      type: SectionType.ARTICLE_TAGS;
      props: SectionArticleTagsProps;
    };
