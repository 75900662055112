import React, { useContext, useState, useEffect, useRef } from "react";
import ScrollElement from "../../../contexts/scroll-element";
import StickyOffsetContext from "../../../contexts/sticky-offset";

import { useScroll, useInView } from "../../../utils/hooks";

import {
  ResponsiveWrapper,
  Content,
  Title,
  Nav,
  NavWrapper,
  NavItem,
  NavText,
  Anchor,
  BackToTop,
  BackToTopArrow,
} from "./style";
import Hover from "../../../utils/classes/Hover";

export interface NavItemProps {
  id: string;
  name: string;
}

export interface SectionPatchNotesBodyProps {
  content: string;
  title: string;
  stickyOffset?: number;
  className?: string;
}

const INTERSECTION_MARGIN = 50;
const SectionPatchNotesBody: React.FC<SectionPatchNotesBodyProps> = ({ content, title, stickyOffset, className }) => {
  const defaultStickyOffset = useContext(StickyOffsetContext);
  if (stickyOffset === undefined) {
    stickyOffset = defaultStickyOffset;
  }
  const scrollElement = useContext(ScrollElement);
  const { bodyData, navData } = preProcess(content);
  const [sectionIdx, setSectionIdx] = useState(0);
  const [isMs] = useState(() => {
    return typeof window !== "undefined" && document.documentElement.classList.contains("is-ms");
  });

  useScroll(
    ({ scrollTop }) => {
      if (!scrollElement) return;

      let currentIdx = 0;
      let isBelow: boolean | 0 | null = true;
      while (navData[currentIdx] && isBelow) {
        const element = scrollElement.querySelector<HTMLElement>(`#${navData[currentIdx].id}`);
        isBelow = element && element.offsetTop && element.offsetTop < scrollTop + INTERSECTION_MARGIN + 50;
        if (isBelow) {
          currentIdx += 1;
        }
      }

      // Have to decrement once because it's idx+1 to always have the first section activated
      currentIdx = Math.max(0, currentIdx - 1);

      if (currentIdx !== sectionIdx) {
        // TODO: happend twice with smooth scroll (OSX)
        setSectionIdx(currentIdx);
      }
    },
    [scrollElement, sectionIdx],
  );

  const backToTopRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (!backToTopRef.current) return;
    const target = backToTopRef.current;

    const hover = new Hover({
      target,
    });

    return () => hover.destroy();
  }, [backToTopRef.current]);
  const [backToTopIntersectionRef, showBackToTop] = useInView({
    threshold: 0,
    rootMargin: "200% 0px -200% 0px",
  });

  /**
   * Move to the concerned section
   * @param index The index of the section wanted
   */
  const handleNavItemClick = (index: number) => {
    if (!scrollElement || !navData[index] || index === sectionIdx) return;

    const element = scrollElement.querySelector<HTMLElement>(`#${navData[index].id}`);
    if (!element || !element.offsetTop) return;

    setSectionIdx(index);

    // Update the URL for easier sharability
    // @TODO we could check onload for the hash and scroll just a bit further to account for the riot bar
    try {
      window.location.hash = navData[index].id;
    } catch (err) {
      /* noop */
    }
    const scrollPos = element.offsetTop - INTERSECTION_MARGIN - 40; // 40 for the odd Riotbar offset
    try {
      scrollElement.scroll({
        top: scrollPos,
        behavior: "smooth",
      });
    } catch (err) {
      // ignore error and set scrollTop on element
      scrollElement.scrollTop = scrollPos;
    }
  };

  return (
    <ResponsiveWrapper className={className}>
      <Content ref={backToTopIntersectionRef} dangerouslySetInnerHTML={{ __html: bodyData }} />
      <NavWrapper>
        <Nav stickyOffset={stickyOffset}>
          <Title>{title}</Title>
          {navData.map((nav, idx) => (
            <NavItem key={nav.id} selected={sectionIdx === idx} onClick={() => handleNavItemClick(idx)}>
              <Anchor />
              <NavText>{nav.name}</NavText>
            </NavItem>
          ))}
        </Nav>
      </NavWrapper>
      <BackToTop
        active={showBackToTop}
        ref={backToTopRef}
        onClick={() => {
          if (!scrollElement) return;
          scrollElement.scrollTop = 0;
        }}
      >
        <BackToTopArrow />
      </BackToTop>
    </ResponsiveWrapper>
  );
};

export default SectionPatchNotesBody;

function preProcess(raw: string) {
  let bodyData = raw;

  // Grab each section title and its id to create the nav dara
  const navData: NavItemProps[] = [];
  const regex = /<h2 id="([^"]*?)">(.+?)<\/h2>/gi;
  while (true) {
    const match = regex.exec(raw);
    if (match === null) break;

    navData.push({
      id: match[1],
      name: match[2],
    });
  }

  // Remove "back to top" links
  bodyData = bodyData.replace(new RegExp("<p>s*<a class=['\"]btt['\"].*?</p>", "g"), "");

  // Return the bodyData and the navData
  return { bodyData, navData };
}
