export default class Popup {
  private url: string;
  private width: number;
  private height: number;
  private name: string | undefined;
  private _popup: Window | null = null;

  constructor(url: string, width = 700, height = 500, name?: string) {
    this.url = url;
    this.width = width;
    this.height = height;
    this.name = name;
  }

  open() {
    if (!this._popup) {
      // open popup
      const left = window.screen.width / 2 - this.width / 2;
      const top = window.screen.height / 2 - this.height / 2;
      this._popup = window.open(
        this.url,
        this.name,
        [
          { feature: "toolbar", value: "no" },
          { feature: "location", value: "no" },
          { feature: "directories", value: "no" },
          { feature: "status", value: "no" },
          { feature: "menubar", value: "no" },
          { feature: "scrollbars", value: "no" },
          { feature: "resizable", value: "no" },
          { feature: "copyhistory", value: "no" },
          { feature: "width", value: this.width },
          { feature: "height", value: this.height },
          { feature: "top", value: top },
          { feature: "left", value: left },
        ]
          .reduce((features: string[], { feature, value }) => {
            features.push(`${feature}=${value}`);
            return features;
          }, [])
          .join(", "),
      );
    }

    // focus popup
    if (this._popup) this._popup.focus();

    return this._popup;
  }
}
