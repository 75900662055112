import React from "react";
import Helmet from "react-helmet";

interface Props {
  title: string;
  description?: string;
  canonicalUrl?: string;
  openGraph: {
    title?: string;
    description?: string;
    image: {
      url: string;
      width?: number;
      height?: number;
    };
    url?: string;
    type?: string;
  };
  twitter: {
    title?: string;
    description?: string;
    image: {
      url: string;
      width?: string;
      height?: string;
    };
    url?: string;
    card?: string;
    site?: string;
    creator?: string;
  };
}

const Meta: React.FC<Props> = ({ title, description, canonicalUrl, openGraph, twitter }) => {
  return (
    <Helmet>
      {/* page */}
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {/* open graph */}
      <meta property="og:title" content={openGraph.title || title} />
      {(openGraph.description || description) && (
        <meta property="og:description" content={openGraph.description || description} />
      )}
      {openGraph.image && <meta property="og:image" content={openGraph.image.url} />}
      {openGraph.image && openGraph.image.width && (
        <meta property="og:image:width" content={openGraph.image.width + ""} />
      )}
      {openGraph.image && openGraph.image.height && (
        <meta property="og:image:height" content={openGraph.image.height + ""} />
      )}
      {(openGraph.url || canonicalUrl) && <meta property="og:url" content={openGraph.url || canonicalUrl} />}
      {openGraph.type && <meta property="og:type" content={openGraph.type} />}

      {/* twitter */}
      <meta name="twitter:title" content={twitter.title || title} />
      {(twitter.description || description) && (
        <meta name="twitter:description" content={twitter.description || description} />
      )}
      {twitter.image && <meta name="twitter:image" content={twitter.image.url} />}
      {twitter.image && twitter.image.width && <meta name="twitter:image:width" content={twitter.image.width + ""} />}
      {twitter.image && twitter.image.height && (
        <meta name="twitter:image:height" content={twitter.image.height + ""} />
      )}
      {(twitter.url || canonicalUrl) && <meta name="twitter:url" content={twitter.url || canonicalUrl} />}
      {twitter.creator && <meta name="twitter:creator" content="@LeagueOfLegends" />}
      {twitter.card && <meta name="twitter:card" content="summary_large_image" />}
      {twitter.site && <meta name="twitter:site" content="@LeagueOfLegends" />}
    </Helmet>
  );
};

export default Meta;
