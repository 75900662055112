import styled, { css } from "styled-components";

import ShareBarComponent from "../../../../components/share-bar";
import TimeAgo from "../../../../components/time-ago";
import { rem } from "../../../../utils/style";
import { COLOR_OUTLINE_LIGHT_GREY, MEDIA_MOBILE_ALL, COLOR_PRIMARY_GOLD } from "../../../../layouts/default/theme";
import LinkComponent from "../../../../components/link";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../components/css";

export const Wrapper = styled.div`
  display: flex;
  font-size: ${rem(10)};
  font-weight: 600;
  ${cssLocalizedUppercase}
  letter-spacing: 0.1em;

  @media ${MEDIA_MOBILE_ALL} {
    ${cssLocalizedLineHeight(14 / 10)}
  }
`;

export const Date = styled(TimeAgo)``;

export const Info = styled.div`
  text-align: center;
`;

export const Category = styled(LinkComponent)`
  color: ${COLOR_PRIMARY_GOLD};
`;

export const Author = styled.span`
  box-sizing: border-box;
`;

export const AuthorLabel = styled.span``;

export const AuthorList = styled.span`
  ${({ theme }) =>
    theme.authorColor &&
    css`
      color: ${theme.authorColor};
    `}
`;

export const ShareBar = styled(ShareBarComponent)``;

const rowLayout = css`
  align-items: center;

  ${({ theme }) => {
    return css`
      color: ${theme.color};
    `;
  }}

  ${Date},
  ${ShareBar} {
    flex: 1 4 auto;
  }

  ${Date} {
    white-space: nowrap;
    padding: 0 30px 0 40px;
  }

  ${ShareBar} {
    padding: 0 40px 0 30px;
  }

  ${Info} {
    flex: 5 1 auto;
    width: 100%;
    border: 1px solid transparent;
    ${({ theme }) =>
      theme.borderColor &&
      css`
        border-color: ${theme.borderColor};
      `}
    border-top: 0;
    border-bottom: 0;

    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.contains-category {
      ${Author} {
        text-align: left;
        border-left: 1px solid transparent;
        ${({ theme }) =>
          theme.borderColor &&
          css`
            border-color: ${theme.borderColor};
          `}
      }
    }
  }

  ${Category},
  ${Author} {
    min-width: 20%;
    padding: 0 10px;
  }

  ${Category} {
    text-align: right;
  }

  ${Author} {
    display: block;
    flex: 1 1 auto;
  }

  ${AuthorLabel} {
    padding-right: 0.8em;
  }
`;

const columnLayout = css`
  flex-wrap: wrap;

  ${Date},
  ${ShareBar} {
    display: flex;
    align-items: center;
  }

  ${Date} {
    box-sizing: border-box;
    width: 36%;
    order: 2;
    padding-left: 20px;
    border: 1px solid transparent;
    ${({ theme }) =>
      theme.borderColor &&
      css`
        border-color: ${theme.borderColor};
      `}
    border-left: none;
    min-height: 40px;
    position: relative;
  }

  ${ShareBar} {
    order: 3;
    width: ${100 - 36 + "%"};
    height: 40px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    ${({ theme }) =>
      theme.borderColor &&
      css`
        border-color: ${theme.borderColor};
      `}

    > * {
      width: 100%;
      height: 32px;
    }
  }

  ${Info} {
    order: 1;
    flex: 5 1 auto;
    width: 100%;
    display: block;
  }

  ${Category} {
    display: none;
  }

  ${AuthorLabel} {
    display: block;
    margin-bottom: 4px;
  }

  ${Author} {
    display: block;
    width: 100%;
    padding: 0 20px;
    text-align: left;
    margin-bottom: 30px;
  }
`;

const BREAKPOINT = 800;
export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${({ theme }) => (theme.breakpoint || BREAKPOINT) + 1 + "px"}) {
    ${rowLayout}
  }

  @media (max-width: ${({ theme }) => (theme.breakpoint || BREAKPOINT) + "px"}) {
    ${columnLayout}
  }
`;
