import { css } from "styled-components";
import { rem } from "../../../utils/style";

import anchorUrl from "../assets/anchor.png";

import {
  COLUMN_WIDTH,
  COLOR_PRIMARY_GOLD,
  COLOR_TEXT_MEDIUM_GREY,
  COLOR_OUTLINE_DARKER,
  COLOR_BACKGROUND_DARK_LIGHT,
  COLOR_TEXT_WHITE,
  COLOR_PRIMARY_BLUE,
  COLOR_PRIMARY_RED,
  COLOR_PRIMARY_GREEN,
  COLOR_TEXT_DARK_GREY,
} from "../../../layouts/default/theme";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../../components/css";

// Inspiration
// https://na.leagueoflegends.com/en/news/game-updates/patch/patch-918-notes

// The body = 14 Columns
const RESPONSIVE_COLUMN_WIDTH = 100 / 14;

export const patchNotesDesktopStyle = css`
  /**
   * * *******************
   * * GENERAL
   * * *******************
   */
  /** Blockquote */
  .blockquote {
    margin: 0 ${RESPONSIVE_COLUMN_WIDTH}%;
    margin-top: ${RESPONSIVE_COLUMN_WIDTH}%;
    margin-bottom: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
    font-size: ${rem(14)};
    ${cssLocalizedLineHeight(1.6)}

    &:first-child {
      margin-top: 7px;
    }

    ::before,
    ::after {
      position: relative;
      top: 0.35em;
      display: inline-block;
      color: ${COLOR_TEXT_DARK_GREY};
      font-size: 2.5em;
      ${cssLocalizedLineHeight(0)}
    }
    ::before {
      content: "“";
    }
    ::after {
      content: "”";
    }
  }
  /** List */
  ul {
    font-size: ${rem(14)};
    ${cssLocalizedLineHeight(1.6)}
    letter-spacing: 0.5px;
    padding-left: ${RESPONSIVE_COLUMN_WIDTH * 0.75}%;

    li {
      margin: 12px 0;
      padding-left: ${RESPONSIVE_COLUMN_WIDTH * 0.25}%;
    }
  }
  a {
    color: #0bc6e3;
  }

  /** Link */
  /* TODO 2019-10-08 jeremboo: Check the actual a design somewhere else */
  .skin-title a,
  p:not(.summary) a {
    display: inline;
    color: ${COLOR_TEXT_WHITE};
    font-weight: 200;
    padding-bottom: 3px;
    ${cssLocalizedLineHeight(2)}
    border-bottom: 2px solid ${COLOR_PRIMARY_BLUE};

    :hover {
      text-decoration: none;
    }
  }
  /** Summary text */
  .summary {
    margin: ${RESPONSIVE_COLUMN_WIDTH * 0.25}px ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
  }
  /** ??? */
  .cboxElement,
  .skins {
    display: block;
    width: 100%;

    img {
      width: 100%;
    }
  }

  /**
    * * *******************
    * * COLUMN SYSTEM
    * * *******************
    */
  .default-2-col {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0 ${RESPONSIVE_COLUMN_WIDTH * 0.25}%;

    > * {
      box-sizing: border-box;
      width: 50%;
      padding: 20px ${RESPONSIVE_COLUMN_WIDTH * 0.25}%;
    }
  }

  /**
   * * *******************
   * * BOX, BORDERS AND LINES
   * * *******************
   */
  .content-border {
    & > img {
      width: 100%;
    }
  }
  div.content-border {
    position: relative;
    border: 1px solid ${COLOR_OUTLINE_DARKER};
    margin: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
    padding: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;

    /* Corner */
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 30px;
      background-color: ${COLOR_BACKGROUND_DARK_LIGHT};
      border-bottom: 2px solid ${COLOR_OUTLINE_DARKER};
      transform-origin: 0;
      transform: rotate(45deg) translate(0px, -120%);
    }
  }
  .divider,
  hr {
    border: 0.5px solid ${COLOR_OUTLINE_DARKER};
    margin: 20px 0;
  }
  .change-detail-title {
    display: flex;
    align-items: center;
    margin-left: ${RESPONSIVE_COLUMN_WIDTH}%;

    font-size: ${rem(14)};

    img {
      width: ${RESPONSIVE_COLUMN_WIDTH * 0.75}%;
      margin-right: ${RESPONSIVE_COLUMN_WIDTH * 0.35}%;
    }
  }

  /**
   * * *******************
   * * HEADER
   * * *******************
   */
  #patch-top + .blockquote {
    font-size: ${rem(16)};
  }
  .context-designers {
    display: flex;
    flex-direction: column;
    margin: 0 ${RESPONSIVE_COLUMN_WIDTH}%;
  }
  .context-designer {
    display: flex;
    align-items: center;
    margin: 5px 0;

    font-size: ${rem(12)};
    ${cssLocalizedUppercase}
    color: ${COLOR_TEXT_MEDIUM_GREY};

    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }

  /**
   * * *******************
   * * TITLE
   * * *******************
   */
  .header-primary {
    display: flex;
    align-items: center;
    margin-top: ${RESPONSIVE_COLUMN_WIDTH}%;
    margin-left: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;

    &::before {
      content: "";
      display: block;
      width: ${RESPONSIVE_COLUMN_WIDTH}%;
      height: 15px;
      margin-top: 2px;
      background: url(${anchorUrl});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 60% center;
    }

    h2 {
      font-size: ${rem(32)};
      ${cssLocalizedItalic}
      font-weight: 800;
      ${cssLocalizedFontFamilySerif}
      ${cssLocalizedUppercase}
      letter-spacing: 2.5px;
      ${cssLocalizedLineHeight(1)}

      color: ${COLOR_PRIMARY_GOLD};

      margin: 0;
    }
  }

  /**
   * * *******************
   * * VIDEO
   * * *******************
   */
  .fluid-width-video-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  /**
   * * *******************
   * * PATCH CHANGE BLOCK
   * * *******************
   */
  .patch-change-block {
    position: relative;

    .reference-link {
      position: absolute;
      top: 0;
      left: 0;

      width: ${RESPONSIVE_COLUMN_WIDTH * 1.5}%;
      min-width: ${COLUMN_WIDTH}px;

      & + .removed,
      & + .updated,
      & + .new {
        margin-top: 21px;
        margin-right: 7px;
        margin-left: ${RESPONSIVE_COLUMN_WIDTH * 2}%;
      }

      img {
        width: 100%;
      }
    }
    .change-title,
    .summary {
      padding-left: ${RESPONSIVE_COLUMN_WIDTH * 2}%;
      padding-top: 5px;
      margin: 0;
    }

    .summary {
      font-size: ${rem(13)};
      ${cssLocalizedLineHeight(1.5)}
    }

    .change-title,
    .change-detail-title {
      font-size: ${rem(24)};
      ${cssLocalizedLineHeight(1.5)}
      font-weight: 700;
      ${cssLocalizedUppercase}
      letter-spacing: 1.5px;

      padding-top: 10px;

      a {
        color: ${COLOR_TEXT_WHITE};
      }

      .removed,
      .updated,
      .new {
        top: 1px;
        margin-right: 7px;
      }
    }

    blockquote {
      margin-left: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
      margin-right: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
    }

    hr {
      margin-left: ${RESPONSIVE_COLUMN_WIDTH}%;
    }

    .attribute-change {
      margin-left: ${RESPONSIVE_COLUMN_WIDTH * 2}%;
    }
  }

  /**
   * * *******************
   * * ATTRIBUTES
   * * *******************
   */
  .attribute,
  .attribute-before,
  .attribute-change {
    font-size: ${rem(12)};
    ${cssLocalizedLineHeight(1.6)}
  }
  .attribute-change {
    margin: 7px ${RESPONSIVE_COLUMN_WIDTH}%;
    margin-left: ${RESPONSIVE_COLUMN_WIDTH * 2}%;
  }
  .attribute {
    color: ${COLOR_TEXT_MEDIUM_GREY};
    ${cssLocalizedUppercase}
    &::after {
      content: ":";
      margin-right: 5px;
    }
  }
  .attribute-before {
    color: ${COLOR_TEXT_MEDIUM_GREY};
    text-decoration: line-through;
  }

  /** Skin */
  .skin-title {
    display: inline-block;
    margin-top: 0;
  }

  /**
   * * *******************
   * * LABELS
   * * *******************
   */
  .removed,
  .updated,
  .new {
    position: relative;
    top: -1px;
    padding: 3px;
    padding-left: 5px;
    margin-right: 4px;
    font-size: ${rem(7)};
    font-weight: 200;
    letter-spacing: 1.5px;
    color: ${COLOR_TEXT_WHITE};
    text-align: center;
    ${cssLocalizedUppercase}

    &.float-left {
      float: left;
    }
  }
  .removed {
    background-color: ${COLOR_PRIMARY_RED};
  }
  .updated {
    background-color: ${COLOR_PRIMARY_BLUE};
  }
  .new {
    background-color: ${COLOR_PRIMARY_GREEN};
  }
`;

export const patchNotesMobileStyle = css`
  .blockquote {
    ${cssLocalizedLineHeight(1.5)}
    margin-left: 0;
    margin-right: 0;
  }
  /** List */
  ul {
    ${cssLocalizedLineHeight(1.3)}
  }
  /** Link */
  .skin-title a,
  p a {
    ::after {
      margin-top: 0;
    }
  }
  /**
   * * *******************
   * * BOX, BORDERS AND LINES
   * * *******************
   */
  .content-border {
    border: none;
    border-bottom: 1px solid ${COLOR_OUTLINE_DARKER};
    margin-left: -5%;
    margin-right: -5%;
    padding: ${RESPONSIVE_COLUMN_WIDTH * 0.5}% ${RESPONSIVE_COLUMN_WIDTH}%;

    &::after {
      display: none;
    }
  }
  .change-detail-title {
    margin-top: 30px;
    margin-left: 0;
    font-size: ${rem(16)};
    img {
      width: ${COLUMN_WIDTH * 0.75}px;
      margin-right: ${RESPONSIVE_COLUMN_WIDTH}%;
    }
  }
  /**
   * * *******************
   * * VIDEO
   * * *******************
   */
  iframe {
    min-height: auto;
  }
  /**
   * * *******************
   * * TITLE
   * * *******************
   */
  .header-primary {
    &::before {
      display: none;
      font-size: ${rem(20)};
    }
  }
  /**
   * * *******************
   * * HEADER
   * * *******************
   */
  #patch-top + .blockquote {
    font-size: ${rem(14)};
    margin-left: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
    margin-right: ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
  }
  .context-designers {
    margin: 0 ${RESPONSIVE_COLUMN_WIDTH * 0.5}%;
  }
  /**
   * * *******************
   * * PATCH CHANGE BLOCK
   * * *******************
   */
  .patch-change-block {
    .reference-link {
      display: block;
      position: relative;

      & + .removed,
      & + .updated,
      & + .new {
        margin-top: 21px;
        margin-right: 7px;
        margin-left: 0px;
      }

      img {
        width: ${COLUMN_WIDTH}px;
      }
    }
    .change-title,
    .summary {
      padding-left: 0px;
    }

    .change-title,
    .change-detail-title {
      padding-top: 0;
      display: flex;
    }

    .change-detail-title {
      font-size: ${rem(16)};
    }

    hr,
    .attribute-change {
      margin-left: 0;
    }

    .blockquote {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .attribute-change {
    margin-left: 0;
  }
  /**
   * * *******************
   * * LABELS
   * * *******************
   */
  .removed,
  .updated,
  .new {
    display: block;
    width: 70px;
    margin-top: 20px;
    margin-bottom: 2px;
    padding: 0 2px;
    font-size: ${rem(12)};

    &.float-left {
      float: none;
    }
  }
  /**
   * * *******************
   * * SKINS
   * * *******************
   */
  .skin-box {
    .content-border {
      display: block;
      border: 0;
    }
  }
`;
