import React from "react";
import { SectionArticleShareProps } from "../.";
import { UrlFieldType } from "../../../../../contentstack/fields/url";

export interface SectionArticleShareContentstackProps extends Pick<SectionArticleShareProps, "title"> {
  url: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = (
  props: SectionArticleShareContentstackProps,
): SectionArticleShareProps => {
  return {
    title: props.title,
    link: props.url,
  };
};
