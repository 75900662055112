import React, { useCallback, useRef, useEffect } from "react";
import Clipboard from "clipboard";

import Popup from "../../utils/popup";

import shareItems, { ShareItem, ShareType } from "./assets/items";
import { Wrapper, List, Item, Button, CopiedMessage } from "./style";
import { useIntl } from "gatsby-plugin-intl";
import BCPLocale, { toBCPLocale } from "../../enums/bcp-locale";

export interface ShareBarProps {
  title: string;
  url: string;
  copiedMessage?: string;
  backgroundColor?: string;
  className?: string;
  testId?: string;
}

const renderUrlTemplate = (template: string, params: { title: string; url: string }): string => {
  return template.replace("%url%", encodeURIComponent(params.url)).replace("%title%", encodeURIComponent(params.title));
};

const CSS_CLASS_BUTTON = "button";
const CSS_CLASS_MESSAGE = "message";
const ShareBar: React.FC<ShareBarProps> = ({ title, url, copiedMessage, backgroundColor, className, testId }) => {
  const intl = useIntl();
  if (!copiedMessage) {
    copiedMessage = intl.formatMessage({ id: "message.copied-to-clipboard" });
  }
  const listRef = useRef<HTMLUListElement>(null);

  const locale = toBCPLocale(intl.locale, BCPLocale["en-us"]);

  // initialize clipboard for clipboard items, or hide if clipboard not supported
  useEffect(() => {
    if (!listRef.current) return;
    if (!Clipboard.isSupported()) return;
    if (typeof listRef.current.querySelectorAll !== "function") return;

    const items = Array.from(
      listRef.current.querySelectorAll<HTMLButtonElement>(`[data-type='${ShareType.CLIPBOARD}']`),
    );

    const clipboards: Clipboard[] = items
      // hide items if clipboard is not supported
      .filter((target) => {
        if (Clipboard.isSupported()) {
          target.classList.remove("hidden");
          return true;
        } else {
          target.classList.add("hidden");
          return false;
        }
      })
      // initialize clipboard for each item
      .map((target) => {
        const button = target.querySelector(`.${CSS_CLASS_BUTTON}`);
        if (!button) return undefined;

        const clipboard = new Clipboard(button, {
          text: () => url,
        });

        let timeout: number;
        clipboard.on("success", () => {
          const messageTarget = target.querySelector(`.${CSS_CLASS_MESSAGE}`);
          if (!messageTarget) return;

          messageTarget.classList.add("active");

          clearTimeout(timeout);
          timeout = window.setTimeout(() => {
            messageTarget.classList.remove("active");
          }, 2000);
        });

        clipboard.on("error", (e) => {
          console.error("Failed to copy url to clipboard.", e.action);
        });

        return clipboard;
      })
      .filter((clipboard): clipboard is Clipboard => !!clipboard);

    return () => {
      clipboards.forEach((clipboard) => clipboard.destroy());
    };
  }, [listRef.current, url]);

  const shareItemFn = useCallback(
    (item: ShareItem) => {
      switch (item.type) {
        case ShareType.POPUP:
          new Popup(renderUrlTemplate(item.urlTemplate, { title, url })).open();
          break;

        case ShareType.NEW_WINDOW:
          window.open(renderUrlTemplate(item.urlTemplate, { title, url }), "_blank");
          break;

        case ShareType.CLIPBOARD:
          break;
      }
    },
    [title, url],
  );

  return (
    <Wrapper className={className}>
      <List ref={listRef}>
        {shareItems.map((item) => {
          if (item.excludedLocales.includes(locale)) {
            return null;
          }
          return (
            <Item key={item.text} data-type={item.type} className={item.className}>
              <Button
                className={CSS_CLASS_BUTTON}
                aria-label={intl.formatMessage({ id: item.text })}
                onClick={() => shareItemFn(item)}
                data-testid={`${testId}:button-${item.className}`}
              >
                <item.icon />
              </Button>
              {item.type === ShareType.CLIPBOARD && (
                <CopiedMessage className={CSS_CLASS_MESSAGE} backgroundColor={backgroundColor}>
                  {copiedMessage}
                </CopiedMessage>
              )}
            </Item>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default ShareBar;
